import React, { useEffect, useState } from 'react';
import {
    Accordion as MuiAccordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import './Compliance.scss';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'; //circle
import StopIcon from '@material-ui/icons/Stop';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { mockData } from './complianceConstants';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { renderComplianceTopHeader } from './CommonComplianceComponents';

const renderCompliantIcon = (compliant) => {
    if (compliant === 'Yes') {
        return <Tooltip title="Yes"><DoneAllIcon style={{ color: 'green' }} /></Tooltip>;
    } else if (compliant === 'No') {
        return <Tooltip title="No"><ReportProblemIcon style={{ color: 'red' }} /></Tooltip>;
    } else {
        return <Tooltip title="Unknown"><ErrorOutlineIcon style={{ color: '#A9A9A9' }} /></Tooltip>;
    }
};

const renderCIAIcon = (value) => {
    if (value === 'Yes') {
        return <Tooltip title="Yes"><StopIcon style={{ color: 'green' }} /></Tooltip>;
    } else if (value === 'No') {
        return <Tooltip title="No"><StopIcon style={{ color: 'red' }} /></Tooltip>;
    } else {
        return <Tooltip title="Unknown"><StopIcon style={{ color: '#A9A9A9' }} /></Tooltip>;
    }
};

const Accordion = ({ item, onChange, isExpanded, children }) => {
    return (
        <Box boxShadow={3} bgcolor="background.paper"
        m={1}>
            <MuiAccordion onChange={onChange} expanded={isExpanded}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Table>
                        <TableRow className='tbl-header-row'>
                            <TableCell className='cell-width acc-title'>{item.title}</TableCell>
                            <TableCell className='cell-width align-center'>{isExpanded ? (<>Confidentiality</>) : null}</TableCell>
                            <TableCell className='cell-width align-center'>{isExpanded ? (<>Integrity</>) : null}</TableCell>
                            <TableCell className='cell-width align-center'>{isExpanded ? (<>Availability</>) : null}</TableCell>
                            <TableCell className='cell-width align-center'>{renderCompliantIcon(item.compliant)}</TableCell>
                            <TableCell className='cell-width align-center'>{item.assesmentDate}</TableCell>
                        </TableRow>
                    </Table>
                </AccordionSummary>
                <AccordionDetails className='pad-0'>{children}</AccordionDetails>
            </MuiAccordion>
        </Box>
    );
};

const Compliance = ({selected_id=null}) => {
    const [data, setData] = useState([]);
    const [selectedSite, setSelectedSite] = useState("NJ");
    const history = useHistory();

    useEffect(() => {
        const jsonData = localStorage.getItem('complianceDatav3');
        if (!jsonData) {
            localStorage.setItem('complianceDatav3', JSON.stringify(mockData));
            setData(mockData);
        }else{
            setData(JSON.parse(jsonData));
        }
        const handleStorageChange = () => {
            const jsonData = localStorage.getItem('complianceDatav3');
            if (jsonData) {
                setData(JSON.parse(jsonData));
            }
        };
        window.addEventListener('storage', handleStorageChange);
        document.body.style.overflow = "hidden";
        return () => {
            window.removeEventListener('storage', handleStorageChange);
            document.body.style.overflow = "auto";
        };
    }, []);

    const setExpanded = (isExpanded, id) => {
        const newData = data.map((item) => {
            if (item.id === id) {
                return { ...item, expanded: isExpanded };
            }
            return item;
        });
        setData(newData);
    }
      const navigateToDashboard = () => {
        history.push("/gov/compliancedashboard")
      }
    return (
      <>
        {selected_id === null
          ? renderComplianceTopHeader("Compliance Maturity Report", "", selectedSite, setSelectedSite)
          : null}
        <div className={selected_id !== null ? "popUpTable" : "cp-main-container"}>
          {selected_id === null ? (
            <div className="navigationParentContainer">
              <div
                className="navigationIconContainer"
                onClick={() => navigateToDashboard()}
              >
                <ArrowBackIosIcon className="icon" />
                <div className="navigationText">Back</div>
              </div>
            </div>
          ) : null}
          <Paper
            className={
              selected_id === null ? "cp-container" : "cp-container popUp"
            }
            sx={{ width: "100%", overflow: "hidden", marginTop: 1 }}
            elevation={0}
          >
            {selected_id === null ? (
              <span className="page-header">Status Overview</span>
            ) : null}
            <TableContainer sx={{ maxHeight: 500 }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow className="tbl-header">
                    <TableCell className="cell-width">Control Name</TableCell>
                    <TableCell className="cell-width"></TableCell>
                    <TableCell className="cell-width"></TableCell>
                    <TableCell className="cell-width"></TableCell>
                    <TableCell className="cell-width" align="center">
                      Status
                    </TableCell>
                    <TableCell className="cell-width" align="center">
                      Assessment Date
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {data.map(
                    (item) =>
                      (selected_id === null || item.id === selected_id) && (
                        <TableRow>
                          <TableCell colSpan="6" className="pad-0">
                            <Accordion
                              isExpanded={item.expanded || selected_id != null}
                              onChange={(e, isExpanded) => {
                                setExpanded(isExpanded, item.id);
                              }}
                              item={item}
                              className="pad-0"
                              sx={{
                                boxShadow: 3,
                                marginBottom: 2,
                                borderRadius: 1,
                              }}
                            >
                              <Table className="tbl-data-container">
                                {item.sections.map((section) => (
                                  <TableRow className="tbl-data-row">
                                    <TableCell className="cell-width">
                                      {section.title}
                                    </TableCell>
                                    <TableCell
                                      className="cell-width"
                                      align="center"
                                    >
                                      {renderCIAIcon(section["C"])}
                                    </TableCell>
                                    <TableCell
                                      className="cell-width"
                                      align="center"
                                    >
                                      {renderCIAIcon(section["I"])}
                                    </TableCell>
                                    <TableCell
                                      className="cell-width"
                                      align="center"
                                    >
                                      {renderCIAIcon(section["A"])}
                                    </TableCell>
                                    <TableCell
                                      className="cell-width"
                                      align="center"
                                    >
                                      {renderCompliantIcon(section.compliant)}
                                    </TableCell>
                                    <TableCell
                                      className="cell-width"
                                      align="center"
                                    >
                                      {section.assesmentDate}
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </Table>
                            </Accordion>
                          </TableCell>
                        </TableRow>
                      )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </div>
      </>
    );
};

export default Compliance;