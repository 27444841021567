import React, { useEffect, useState } from "react";
import "./Compliance.scss";
import { PieChart } from "@mui/x-charts/PieChart";
import Box from "@mui/material/Box";
import { complianceStatusIcons, mockData } from "./complianceConstants";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import CompliancePopup from "./CompliancePopup";
import { renderComplianceTopHeader } from "./CommonComplianceComponents";
import Chip from '@material-ui/core/Chip';
import { withStyles } from "@material-ui/core/styles";

const StandardChip = withStyles((theme) => ({
    root: {
      fontSize: 22,
      margin: 10,
    },
  }))(Chip);

function ComplianceDashboard() {
  const [dashBoardData, setDashBoardData] = useState([]);
  const [headerData, setHeaderData] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);
  const [selectedSite, setSelectedSite] = useState("NJ");
  const history = useHistory();

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  const processComplianceData = () => {
    let jsonData = localStorage.getItem("complianceDatav3");
    if (!jsonData) {
      localStorage.setItem("complianceDatav3", JSON.stringify(mockData));
      jsonData = JSON.stringify(mockData);
    }

    const complianceData = JSON.parse(jsonData);

    let totalYes = 0;
    let totalNo = 0;
    let totalUnknown = 0;
    let latestDate = null;

    const processedData = complianceData.map((item, index) => {
      const sectionCounts = item.sections.reduce(
        (acc, section) => {
          if (section.compliant === "Yes") acc.yes++;
          else if (section.compliant === "No") acc.no++;
          else if (section.compliant === "Unknown") acc.unknown++;
          return acc;
        },
        { yes: 0, no: 0, unknown: 0 }
      );

      const total =
        sectionCounts.yes + sectionCounts.no + sectionCounts.unknown;
      const yesPercentage = (sectionCounts.yes / total) * 100;
      const noPercentage = (sectionCounts.no / total) * 100;
      const unknownPercentage = (sectionCounts.unknown / total) * 100;

      // Update overall counts
      totalYes += sectionCounts.yes;
      totalNo += sectionCounts.no;
      totalUnknown += sectionCounts.unknown;

      // Update latest date
      const currentDate = new Date(item.assesmentDate);
      if (!latestDate || currentDate > new Date(latestDate)) {
        latestDate = item.assesmentDate;
      }

      const maxCount = Math.max(
        sectionCounts.yes,
        sectionCounts.no,
        sectionCounts.unknown
      );
      const status =
        maxCount === sectionCounts.yes
          ? "success"
          : maxCount === sectionCounts.no
          ? "error"
          : "warning";

      return {
        id: item.id,
        title: item.title,
        date: item.assesmentDate,
        status,
        pieChartData: [
          {
            data: [
              {
                id: 0,
                value: yesPercentage,
                label: `Yes: ${yesPercentage.toFixed(1)}%`,
              },
              {
                id: 1,
                value: noPercentage,
                label: `No: ${noPercentage.toFixed(1)}%`,
              },
              {
                id: 2,
                value: unknownPercentage,
                label: `Unknown: ${unknownPercentage.toFixed(1)}%`,
              },
            ],
          },
        ],

        color: ["#14b8a6", "#e93123", "#A9A9A9	"],
      };
    });

    // Calculate overall percentages
    const overallTotal = totalYes + totalNo + totalUnknown;
    const overallYesPercentage = (totalYes / overallTotal) * 100;
    const overallNoPercentage = (totalNo / overallTotal) * 100;
    const overallUnknownPercentage = (totalUnknown / overallTotal) * 100;

    const headerData = {
      overallYesPercentage,
      overallNoPercentage,
      overallUnknownPercentage,
      lastUpdate: latestDate,
    };

    // Update state
    setDashBoardData(processedData);
    setHeaderData(headerData);
  };

  useEffect(() => {
    processComplianceData();

    const handleStorageChange = () => {
      processComplianceData();
    };

    window.addEventListener("storage", handleStorageChange);

    // Cleanup the listener
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const handleViewAllClick = () => {
    history.push("/gov/compliance");
  };

  const renderComplianceMiddleHeader = () => {
    return (
      <div className="complianceChildContainer disp-flex">
        <div className="boxContainer">
          <div className="score-container">
            <div className="pie-chart-container">
              <Box>
                <PieChart
                  series={[
                    {
                      data: [
                        {
                          id: 0,
                          value: headerData?.overallYesPercentage,
                          label: `Yes: ${headerData?.overallYesPercentage?.toFixed(
                            1
                          )}%`,
                        },
                        {
                          id: 1,
                          value: headerData?.overallNoPercentage,
                          label: `No: ${headerData?.overallNoPercentage?.toFixed(
                            1
                          )}%`,
                        },
                        {
                          id: 2,
                          value: headerData?.overallUnknownPercentage,
                          label: `Unknown: ${headerData?.overallUnknownPercentage?.toFixed(
                            1
                          )}%`,
                        },
                      ],
                      innerRadius: 7,
                      paddingAngle: 8,
                    },
                  ]}
                  colors={["#14b8a6", "#e93123", "#A9A9A9	"]}
                  width={300}
                  height={280}
                  slotProps={{
                    legend: { hidden: true }, // Hides the legend
                  }}
                  sx={{
                    "& .MuiPieArc-root": {
                      stroke: "white",
                      strokeWidth: 1,
                    },
                  }}
                  margin={{ left: 100 }}
                />
              </Box>
            </div>
            <div className="text-container">
              <p className="score-title">Current Score</p>
              <p className="score-value">{`${Math.floor(
                headerData?.overallYesPercentage
              )}%`}</p>
              <p className="last-updated">{`last Updated: ${headerData?.lastUpdate}`}</p>
            </div>
            <div
              className="complianceBadge"
              title={Math.floor(headerData?.overallYesPercentage)}
            >
              <img
                src={"/dashboardIcons/complianceBadge.svg"}
                alt="score icon"
              />
            </div>
          </div>
        </div>
        <div className="boxContainer standards">
          <div className="box-border">
            <div>
              <p className="score-title">Standards:</p>
            </div>
            <div className="align-center score-value">
              <StandardChip classes={"score-value"} label="GLI-19" />
              <StandardChip label="NJ DGE" />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderScoreBreakDownTitle = () => {
    return (
      <div className="score-breakdowns-container">
        <div className="scoreCardTitle">Score Breakdowns</div>
        <div className="scoreCardSubtitle">
          Your individual Category scores.
        </div>
      </div>
    );
  };

  const renderCards = () => {
    return (
      <div className="cards-container">
        {dashBoardData.map((card) => (
          <div
            key={card.id}
            className={`card ${card.status}`}
            onClick={() => setSelectedCard(card)}
          >
            <div className="card-header">
              {card.pieChartData ? (
                <div className="compliancePieContainer">
                  <Box>
                    <PieChart
                      series={card.pieChartData}
                      colors={card.color}
                      width={200}
                      height={150}
                      sx={{
                        "& .MuiPieArc-root": {
                          stroke: "black",
                          strokeWidth: 1.5,
                        },
                      }}
                      margin={{ left: 5 }}
                      slotProps={{
                        legend: { hidden: true },
                      }}
                    />
                  </Box>
                </div>
              ) : (
                <div>No Data</div>
              )}
              <div className="complianceStatusContainer">
                <img
                  src={complianceStatusIcons[card.status]}
                  alt={card.status}
                />
                <div className="date">{card.date}</div>
              </div>
            </div>
            <div className="card-body">
              <div className="navigate-icon-container">
                <h3 className="card-title">{card.title}</h3>
                <div className="navigateIcon">
                  <NavigateNextIcon style={{ fontSize: 30, color: "#888" }} />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      {selectedCard && <div className="backdrop" />}
      {renderComplianceTopHeader("iGaming (RGS) Security","Scores are improving against relevant standard", selectedSite, setSelectedSite)}
      <div className="scrollable">
        {renderComplianceMiddleHeader()}
        <div className="complianceChildContainer scoreCards">
          {renderScoreBreakDownTitle()}
          <div className="cardsParentContainer">
            <CompliancePopup
              isOpen={!!selectedCard}
              onClose={() => setSelectedCard(null)}
              selectedCard={selectedCard}
            />
            {renderCards()}
            <button className="view-all-btn" onClick={() => handleViewAllClick()}>
              View Report
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default ComplianceDashboard;
