import React from "react";
import Popup from "reactjs-popup";
import { PieChart } from "@mui/x-charts/PieChart";
import "./Compliance.scss";
import Compliance from "./Compliance";

const CompliancePopup = ({ isOpen, onClose, selectedCard }) => {
  if (!selectedCard) return null;

  return (
    <Popup open={isOpen} closeOnDocumentClick onClose={onClose} modal>
      <div className="popup-container">
        <div className="compliancePopupHeaderContainer">
          <div className="complianceTitleContainer">
            <div className="popup-title">{selectedCard.title}</div>
            <div className="popup-compliance">
              <PieChart
                series={selectedCard.pieChartData}
                colors={selectedCard.color}
                width={180}
                height={100}
                sx={{
                  "& .MuiPieArc-root": {
                    stroke: "black",
                    strokeWidth: 1.5,
                  },
                }}
                slotProps={{
                  legend: { hidden: true },
                }}
              />
            </div>
          </div>
          <div className="complianceClose">
            <button className="close-button" onClick={onClose}>
              &times;
            </button>
          </div>
        </div>
        <div className="complianceTableContainer">
          <Compliance selected_id={selectedCard.id}/>
        </div>
      </div>
    </Popup>
  );
};

export default CompliancePopup;
