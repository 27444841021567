import { JsonEditor } from 'json-edit-react'
import React, { useState, useEffect } from 'react';
import {
    Paper,
} from "@material-ui/core";
const ComplianceEditor = () => {
    const [data, setData] = useState({});

    useEffect(() => {
        const storedData = localStorage.getItem('complianceDatav3');
        if (storedData) {
            setData(JSON.parse(storedData));
        }
    }, []);

    const handleChange = (updatedData) => {
        setData(updatedData);
        localStorage.setItem('complianceDatav3', JSON.stringify(updatedData));
    };

    return (
        <Paper
                    className="cp-container"
                    sx={{ width: "100%", overflow: "hidden", marginTop: 1 }}
                    elevation={0}
                >
            <JsonEditor data={ data }
    setData={ handleChange } />
        </Paper>
    );
};

export default ComplianceEditor;