export const complianceStatusIcons = {
  success : "/dashboardIcons/done_all.svg",
  warning: "/dashboardIcons/error_outline.svg",
  error: "/dashboardIcons/warning.svg"
}

export const mockData = [
  {
    "id": "B.2",
    "title": "System Operations & Security",
    "compliant": "Yes",
    "assesmentDate": "20-Jan-2025",
    "expanded": false,
    "sections": [
      {
        "id": "B2.1",
        "title": "B.2.1.b System Security Monitoring",
        "compliant": "Yes",
        "assesmentDate": "20-Jan-2025",
        "C": "Yes",
        "I": "Yes",
        "A": "Yes",
        "remark": null
      },
      {
        "id": "B2.1",
        "title": "B.2.1.c System Security Incident Management",
        "compliant": "Yes",
        "assesmentDate": "20-Jan-2025",
        "C": "Yes",
        "I": "Yes",
        "A": "Yes",
        "remark": null
      },
      {
        "id": "B2.3",
        "title": "B.2.3 Logical Access Control - Secure Authentication",
        "compliant": "Yes",
        "assesmentDate": "20-Jan-2025",
        "C": "Yes",
        "I": "Yes",
        "A": "Yes",
        "remark": null
      },
      {
        "id": "B2.4",
        "title": "B.2.4 Logical Access Control - User Authorization",
        "compliant": "No",
        "assesmentDate": "20-Jan-2025",
        "C": "No",
        "I": "No",
        "A": "Yes",
        "remark": null
      },
      {
        "id": "B2.9",
        "title": "B.2.9 Identify & Classify - Critical Asset",
        "compliant": "Yes",
        "assesmentDate": "20-Jan-2025",
        "C": "Yes",
        "I": "Yes",
        "A": "Yes",
        "remark": null
      }
    ]
  },
  {
    "id": "B.3",
    "title": "Data Integrity",
    "compliant": "No",
    "assesmentDate": "20-Jan-2025",
    "expanded": false,
    "sections": [
      {
        "id": "B3.1",
        "title": "B.3.1 Data Security",
        "compliant": "Yes",
        "assesmentDate": "20-Jan-2025",
        "C": "Yes",
        "I": "Yes",
        "A": "Unknown",
        "remark": null
      },
      {
        "id": "B3.2",
        "title": "B.3.2 Data Alteration - Monitoring & Reporting",
        "compliant": "No",
        "assesmentDate": "20-Jan-2025",
        "C": "No",
        "I": "No",
        "A": "No",
        "remark": null
      },
      {
        "id": "B3.3",
        "title": "B.3.3 Backup Frequency",
        "compliant": "No",
        "assesmentDate": "20-Jan-2025",
        "C": "No",
        "I": "No",
        "A": "No",
        "remark": null
      },
      {
        "id": "B3.4",
        "title": "B.3.4 Backup - Storage Location",
        "compliant": "Yes",
        "assesmentDate": "20-Jan-2025",
        "C": "Yes",
        "I": "Yes",
        "A": "Yes",
        "remark": null
      },
      {
        "id": "B3.9",
        "title": "B.3.9 Business Continuity & DR Plan",
        "compliant": "Yes",
        "assesmentDate": "20-Jan-2025",
        "C": "Yes",
        "I": "Yes",
        "A": "Yes",
        "remark": null
      }
    ]
  },
  {
    "id": "B.4",
    "title": "Communications",
    "compliant": "Unknown",
    "assesmentDate": "20-Jan-2025",
    "expanded": false,
    "sections": [
      {
        "id": "B4.2",
        "title": "B.4.2 Connectivity - System Access Monitoring",
        "compliant": "Yes",
        "assesmentDate": "20-Jan-2025",
        "C": "Yes",
        "I": "Yes",
        "A": "Unknown",
        "remark": null
      },
      {
        "id": "B4.3",
        "title": "B.4.3 Communication Protocol - Monitor Integrity & Encryption",
        "compliant": "Unknown",
        "assesmentDate": "20-Jan-2025",
        "C": "Unknown",
        "I": "Unknown",
        "A": "Unknown",
        "remark": null
      },
      {
        "id": "B4.4",
        "title": "B.4.4 Communication over Internet",
        "compliant": "Unknown",
        "assesmentDate": "20-Jan-2025",
        "C": "Unknown",
        "I": "Unknown",
        "A": "Unknown",
        "remark": null
      },
      {
        "id": "B4.5",
        "title": "B.4.5 WLAN communications",
        "compliant": "No",
        "assesmentDate": "20-Jan-2025",
        "C": "No",
        "I": "No",
        "A": "No",
        "remark": null
      },
      {
        "id": "B4.6",
        "title": "B.4.6 Network Security Management",
        "compliant": "Unknown",
        "assesmentDate": "20-Jan-2025",
        "C": "Unknown",
        "I": "Unknown",
        "A": "Unknown",
        "remark": null
      },
      {
        "id": "B4.7",
        "title": "B.4.7 Active and Passive Attacks - Test & Incident Response Plan",
        "compliant": "Unknown",
        "assesmentDate": "20-Jan-2025",
        "C": "Unknown",
        "I": "Unknown",
        "A": "Unknown",
        "remark": null
      }
    ]
  },
  {
    "id": "B.5",
    "title": "Third-Party Service Providers",
    "compliant": "Yes",
    "assesmentDate": "20-Jan-2025",
    "expanded": false,
    "sections": [
      {
        "id": "B5.1",
        "title": "B.5.1 RGS & Third-Party Communications - Auditable",
        "compliant": "No",
        "assesmentDate": "20-Jan-2025",
        "C": "No",
        "I": "No",
        "A": "No",
        "remark": null
      },
      {
        "id": "B5.3",
        "title": "B.5.3 RGS & Third-Party Data Processing & Governance",
        "compliant": "Yes",
        "assesmentDate": "20-Jan-2025",
        "C": "Yes",
        "I": "Yes",
        "A": "Yes",
        "remark": null
      }
    ]
  },
  {
    "id": "B.6",
    "title": "Technical Controls",
    "compliant": "No",
    "assesmentDate": "20-Jan-2025",
    "expanded": false,
    "sections": [
      {
        "id": "B6.1",
        "title": "B.6.1.a DNS Server - Primary & Secondary",
        "compliant": "No",
        "assesmentDate": "20-Jan-2025",
        "C": "No",
        "I": "No",
        "A": "No",
        "remark": null
      },
	  {
        "id": "B6.1",
        "title": "B.6.1.c DNS Server - Access Control",
        "compliant": "No",
        "assesmentDate": "20-Jan-2025",
        "C": "No",
        "I": "No",
        "A": "No",
        "remark": null
      },
	  {
        "id": "B6.1",
        "title": "B.6.1.f DNS Server - MFA",
        "compliant": "No",
        "assesmentDate": "20-Jan-2025",
        "C": "No",
        "I": "No",
        "A": "No",
        "remark": null
      },
      {
        "id": "B6.2",
        "title": "B.6.2.a Cryptographic Controls - PII Data is Encrypted",
        "compliant": "Yes",
        "assesmentDate": "20-Jan-2025",
        "C": "Yes",
        "I": "Yes",
        "A": "Yes",
        "remark": null
      },
	  {
        "id": "B6.2",
        "title": "B.6.2.a Cryptographic Controls - PII Data is Encrypted",
        "compliant": "Yes",
        "assesmentDate": "20-Jan-2025",
        "C": "Yes",
        "I": "Yes",
        "A": "Yes",
        "remark": null
      },
      {
        "id": "B6.3",
        "title": "B.6.3 Encryption Key Management",
        "compliant": "No",
        "assesmentDate": "20-Jan-2025",
        "C": "No",
        "I": "No",
        "A": "No",
        "remark": null
      }
    ]
  },
  {
    "id": "B.7",
    "title": "Remote Access & Firewalls",
    "compliant": "Yes",
    "assesmentDate": "20-Jan-2025",
    "expanded": false,
    "sections": [
      {
        "id": "B7.1",
        "title": "B.7.1 Remote Access Security",
        "compliant": "Yes",
        "assesmentDate": "20-Jan-2025",
        "C": "Yes",
        "I": "Yes",
        "A": "Yes",
        "remark": null
      },
      {
        "id": "B7.2",
        "title": "B.7.2 Remote Access Procedures & Guest Account",
        "compliant": "Yes",
        "assesmentDate": "20-Jan-2025",
        "C": "Yes",
        "I": "Yes",
        "A": "Yes",
        "remark": null
      },
      {
        "id": "B7.3",
        "title": "B.7.3 Remote Access Activity Log",
        "compliant": "Yes",
        "assesmentDate": "20-Jan-2025",
        "C": "Yes",
        "I": "Unknown",
        "A": "Yes",
        "remark": null
      },
      {
        "id": "B7.4",
        "title": "B.7.4 Firewall Monitoring",
        "compliant": "Yes",
        "assesmentDate": "20-Jan-2025",
        "C": "Yes",
        "I": "Yes",
        "A": "Yes",
        "remark": null
      },
      {
        "id": "B7.5",
        "title": "B.7.5 Firewall Audit logs",
        "compliant": "No",
        "assesmentDate": "20-Jan-2025",
        "C": "No",
        "I": "No",
        "A": "No",
        "remark": null
      },
      {
        "id": "B7.6",
        "title": "B.7.6 Firewall Rules Review",
        "compliant": "Yes",
        "assesmentDate": "20-Jan-2025",
        "C": "Yes",
        "I": "Yes",
        "A": "Yes",
        "remark": null
      }
    ]
  },
  {
    "id": "B.8",
    "title": "Change Management",
    "compliant": "Yes",
    "assesmentDate": "20-Jan-2025",
    "expanded": false,
    "sections": [
      {
        "id": "B8.4",
        "title": "B.8.4 Patches",
        "compliant": "Yes",
        "assesmentDate": "20-Jan-2025",
        "C": "Yes",
        "I": "Yes",
        "A": "Unknown",
        "remark": null
      }
    ]
  },
  {
    "id": "B.9",
    "title": "Periodic Security Testing",
    "compliant": "Yes",
    "assesmentDate": "20-Jan-2025",
    "expanded": false,
    "sections": [
      {
        "id": "B9.1",
        "title": "B.9.1 Technical Security Testing",
        "compliant": "Yes",
        "assesmentDate": "20-Jan-2025",
        "C": "Yes",
        "I": "Yes",
        "A": "Yes",
        "remark": null
      },
      {
        "id": "B9.2",
        "title": "B.9.2 Vulnerability Assessment",
        "compliant": "Yes",
        "assesmentDate": "20-Jan-2025",
        "C": "Yes",
        "I": "Yes",
        "A": "Yes",
        "remark": null
      },
      {
        "id": "B9.3",
        "title": "B.9.3 Penetration Testing",
        "compliant": "Yes",
        "assesmentDate": "20-Jan-2025",
        "C": "Yes",
        "I": "Yes",
        "A": "Yes",
        "remark": null
      },
      {
        "id": "B9.4",
        "title": "B.9.4 ISMS Audit",
        "compliant": "Yes",
        "assesmentDate": "20-Jan-2025",
        "C": "Yes",
        "I": "Yes",
        "A": "Yes",
        "remark": null
      },
      {
        "id": "B9.5",
        "title": "B.9.5 Cloud Service Audit",
        "compliant": "Unknown",
        "assesmentDate": "20-Jan-2025",
        "C": "Unknown",
        "I": "Unknown",
        "A": "Unknown",
        "remark": null
      }
    ]
  }
];
