export const renderComplianceTopHeader = (title, subtitle, selectedSite, setSelectedSite) => {
    
    const handleSiteChange = (event) => {
      setSelectedSite(event.target.value);
    };
  
    return (
      <div className="complianceHeader compliance">
        <div className="complianceSecondaryHeader">
          <div className="riskGovernanceHeaderContainer">
            <div>
              <h2 className="complianceTitle">{title}</h2>
              <h3 className="compliance-sub-title">{subtitle}</h3>
            </div>
            <div className="siteDropdownContainer">
              <span className="siteLabel">Site: </span>
              <select
                className="siteDropdown"
                value={selectedSite}
                onChange={() => handleSiteChange()}
              >
                <option value="NJ">NJ</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    );
  };